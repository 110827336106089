$fa-font-path: "@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "npm/bootstrap.min";
@import "external/datetimepicker.min";
@import "external/daterangepicker.min";
@import "npm/handsontable.min";
@import "npm/sweetalert.min";
@import "external/clockpicker.min";
@import "npm/datatables.min";
@import "npm/fullcalendar.min";
@import "npm/jquery-ui.min";
@import "external/spectrum";
@import "npm/fixedHeader.bootstrap.min";
@import "external/lightbox";
@import "npm/tom-select.min";
@import "../src/forms/edit-controls/select-control";

.btn,
.hover-panel {
  transition: background-color 150ms linear, color 150ms linear;
}

.header-experimental {
  width: fit-content;
  color: #666666;
  margin: 0px;
  padding: 10px;
  border: 1px solid #dddddd;
  border-bottom: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.nav-tabs-experimental {
  border: 1px solid #dddddd;

  li > a {
    margin: 0px;
  }
}

.btn-dashboard {
  height: 50px;
  border-radius: 0px;
}

.btn-shortcut {
  margin-right: 5px;
  padding: 1px 10px 1px 2px;
  border-radius: 50px;
}

.span-shortcut {
  padding: 2px;
  padding-right: 5px;
  color: black;
  cursor: move;
}

.img-circle {
  border-radius: 50%;
}

.inline {
  display: inline;
}

.draggable {
  cursor: pointer;
}

.ui-draggable-dragging {
  position: relative;
  z-index: 500;
}

.btn-draggable {
  padding: 1px 15px 3px 2px;
  border-radius: 50px;
}

.viewParameterCheckbox {
  margin-top: 0px;
  margin-bottom: 0px;
}

.viewParameterCheckbox label {
  padding: 0px;
}

.panel.table.item-panel {
  margin: 0px;
}

.panel.table.item-panel .normal-btn {
  float: right;
  margin-top: -8px;
  margin-right: -6px;
  border-radius: 30px;
}

table.dataTable {
  margin-top: 0px !important;
}

.dataTables_filter {
  padding-top: 5px !important;
}

div.dataTables_filter input {
  height: 28px !important;
}

.center-light-border {
  border: 1px lightgray solid;
  text-align: center;
}

.modal-open {
  position: relative !important;
}

.fc-event {
  border-width: 2px !important;
  border-style: dashed;
}

.fc-bg-event {
  border-style: none !important;
}

.fc-event-main-frame {
  flex-direction: column;
}

.fc-toolbar-title {
  text-transform: capitalize;
}

.fc-toolbar.fc-header-toolbar {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.fc-left > h2 {
  font-size: 27px;
}

.relative {
  position: relative;
}

.absolute-button {
  position: absolute;
  border-radius: 30px;
  z-index: 500;
  height: 38px;
  width: 40px;
}

.absolute-dropdown {
  position: absolute;
  border-radius: 30px;
  z-index: 500;
  top: 5px;
  right: 5px;
}

.menu-option-padding {
  padding-left: 15px;
  padding-right: 15px;
  display: -webkit-inline-box;
}

.margin-none {
  margin: 0px;
}

body {
  height: 93vh;
}

h1 {
  color: red;
  font-size: 40px;
  font-weight: bold;
}

a {
  cursor: pointer;
  color: #666666;
}

.justify {
  text-align: justify;
}

.pagination > li > {
  a {
    &:focus,
    &:hover {
      color: #666666;
    }
  }
  span {
    &:focus,
    &:hover {
      color: #666666;
    }
  }
  a,
  span {
    color: #666666;
  }
}

.form-inline > .checkbox {
  padding-left: 10px;
}

.panel-body > .row > .col-xs-6 > .form-inline {
  margin-bottom: 0px;
}

.options > .checkbox {
  padding-left: 10px;
}

.overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.8;
  z-index: 9000;
}

.loader-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 1000;
}

.loader {
  border-radius: 50%;
  width: 10em;
  height: 10em;
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  margin: 0px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid rebeccapurple;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: wemply-loader 0.4s infinite linear;
  animation: wemply-loader 0.4s infinite linear;
}

@-webkit-keyframes wemply-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes wemply-loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.options {
  float: left;
  padding-top: 13px;
  padding-left: 10px;
}

.newCompanyLink {
  color: white;
  position: absolute;
  bottom: 0;
}

.dataTables_info {
  padding-top: 20px !important;
  text-align: center;
}

.dataTables_paginate {
  float: right;
}

.main {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
}

.dt-buttons {
  padding-left: 10px;
  padding-top: 5px;
  float: left !important;
}

.dataTables_length {
  padding-top: 17px;
  padding-left: 10px;
}

.dataTables_filter {
  padding-top: 10px;
  padding-right: 10px;
}

.dataTables_paginate {
  padding: 10px;
}

.brand-image {
  margin-top: -15px;
}

.map {
  height: 300px;
}

.form-group {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.main-button {
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 5px;
}

.close-button {
  margin-right: 15px;
  margin-top: 5px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-right-5 {
  margin-right: 5px;
}

.small-icon {
  font-size: 1em !important;
}

.nav .open > a {
  color: white;
  &:hover,
  &:focus {
    color: white;
  }
}

.navbar-default {
  .companyname:focus {
    color: grey;
  }
  .navbar-nav > {
    li > a {
      &:hover,
      &:focus {
        color: grey;
      }
    }
    .open > a {
      color: white;
      &:hover,
      &:focus {
        color: white;
      }
    }
  }
  .companyname:focus {
    color: white;
  }
  .navbar-nav > li > a {
    &:hover,
    &:focus {
      color: white;
    }
  }
}

.hover-panel {
  &:hover {
    background-color: #dfdfdf !important;
    cursor: pointer;
  }
  margin-bottom: 0px;
}

.inner-hover-panel {
  margin-top: 15px;
}

.clickable {
  cursor: pointer !important;
}

.plus-button {
  width: 128px;
  height: 128px;
  background-color: lightgray;
  text-align: center;
}

.plus-inside-button {
  color: white;
  width: 64px;
  height: 64px;
  font-size: 64px;
  margin-top: 32px;
}

.panel-click {
  cursor: pointer !important;
  h4 {
    cursor: pointer !important;
  }
}

h4 > a {
  text-decoration: none !important;
}

.modal-open {
  position: fixed;
}

.modal {
  -webkit-overflow-scrolling: auto !important;
  overflow-y: auto !important;
}

button {
  cursor: pointer !important;
}

.text-primary {
  color: rebeccapurple;
}

.clockpicker-tick {
  &.active,
  &:hover {
    background-color: mediumpurple;
  }
}

.clockpicker-canvas-bearing,
.clockpicker-canvas-fg {
  fill: rebeccapurple;
}

.clockpicker-canvas-bg,
.clockpicker-canvas-bearing,
.clockpicker-canvas-fg {
  fill: mediumpurple;
}

.clockpicker-canvas line {
  stroke: mediumpurple;
  stroke-width: 1;
  stroke-linecap: round;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > {
  td:first-child:before,
  th:first-child:before {
    background-color: rebeccapurple;
  }
}

.btn-default:hover {
  background-color: #000000a0;
  background-image: linear-gradient(#000000a0, #000000a0);
  filter: none;
}

.btn-default {
  background-color: #404040;
  background-image: linear-gradient(#404040, #404040);
  border-color: #404040;
}

.navbar-default {
  .companyname:hover {
    color: white;
    background: purple;
  }
  .navbar-nav > .open > a {
    background: purple;
    &:hover,
    &:focus {
      background: purple;
    }
  }
}

.dropdown-menu > li > a:hover,
.navbar-default .navbar-nav > li > a:hover {
  color: white;
  background: purple;
}

a:hover {
  color: rebeccapurple;
}

.daterangepicker td.active {
  background: rebeccapurple;
  border-color: rebeccapurple;
  &:hover {
    background: rebeccapurple;
    border-color: rebeccapurple;
  }
}

.applyBtn,
.btn-primary,
.buttons-html5 {
  background: rebeccapurple;
  border-color: rebeccapurple;
}

.fc-button {
  background: rebeccapurple !important;
  border-color: rebeccapurple !important;
}

.ranges li:hover {
  background: purple;
  border-color: purple;
}

.applyBtn {
  &:hover,
  &:focus,
  &:active {
    background: purple;
    border-color: purple;
  }
}

.btn-primary {
  &:hover,
  &:focus,
  &:active,
  &.active {
    background: purple;
    border-color: purple;
  }
}

.fc-button {
  &:hover,
  &:focus,
  &:active,
  &.active {
    background: purple !important;
    border-color: purple !important;
  }
}

.fc-button-active {
  background: purple !important;
  border-color: purple !important;
}

.buttons-html5 {
  &:hover,
  &:focus,
  &:active,
  &.active {
    background: purple;
    border-color: purple;
  }
}

.open .dropdown-toggle.btn-primary {
  background: purple;
  border-color: purple;
}

.btn-primary {
  &:active:hover,
  &.active:hover {
    background: purple;
    border-color: purple;
  }
}

.open > .dropdown-toggle.btn-primary:hover {
  background: purple;
  border-color: purple;
}

.btn-primary {
  &:active:focus,
  &.active:focus {
    background: purple;
    border-color: purple;
  }
}

.open > .dropdown-toggle.btn-primary:focus {
  background: purple;
  border-color: purple;
}

.btn-primary {
  &:active.focus,
  &.active.focus {
    background: purple;
    border-color: purple;
  }
}

.open > .dropdown-toggle.btn-primary.focus {
  background: purple;
  border-color: purple;
}

.nav-tabs > li > a:hover {
  color: white;
  background: purple;
}

.input-mini.active:focus {
  border-color: purple;
}

.form-control:focus {
  border-color: purple;
  border-color: rebeccapurple;
}

.select2-container--bootstrap {
  &.select2-container--focus .select2-selection,
  &.select2-container--open .select2-selection,
  .select2-dropdown {
    border-color: rebeccapurple;
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: rebeccapurple;
  }
}

.bootstrap-datetimepicker-widget table td {
  &.active {
    background-color: rebeccapurple;
    &:hover {
      background-color: rebeccapurple;
    }
  }
  &.today:before {
    border-bottom-color: rebeccapurple;
  }
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: white;
    background: purple;
  }
  .timer.btn {
    margin-left: 10px;

    .mobile {
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.timer-text {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.nav-tabs > li > a,
.panel {
  border-radius: 0px 0px 0 0;
}

.quickways {
  padding-top: 20px;

  .panel-body {
    height: 130px;
  }
  .panel {
    margin-bottom: 0px;
  }
  .col-xs-12,
  .col-sm-6,
  .col-md-4 {
    padding: 0px;
  }
}

@media (min-width: 767px) {
  .nav-tabs > li > a {
    font-size: 20px;
  }
}

.active > a {
  box-shadow: inset 0 3px 0 purple;
}

.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}

.calendar-checkbox {
  margin: 20px !important;
}

.calendar-checkboxes-container {
  display: flex;
}

.file-hide {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.checkbox label:after,
.radio label:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  border: 1px solid #a9a9a9;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
}

.radio .cr {
  position: relative;
  border: 1px solid #a9a9a9;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
  border-radius: 50%;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 20%;
}

.radio .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 20%;
  margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
  display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
  opacity: 0.5;
}

.dropdown-menu {
  .sub-menu {
    left: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    margin-top: -1px;
  }
  li:hover .sub-menu {
    visibility: visible;
  }
}

.dropdown:hover .sub-menu {
  display: block;
}

@media (max-width: 767px) {
  .dropdown:hover .sub-menu {
    display: none;
  }
}

.navbar .sub-menu {
  &:before {
    border-bottom: 7px solid transparent;
    border-left: none;
    border-right: 7px solid rgba(0, 0, 0, 0.2);
    border-top: 7px solid transparent;
    left: -7px;
    top: 10px;
  }
  &:after {
    border-top: 6px solid transparent;
    border-left: none;
    border-right: 6px solid #fff;
    border-bottom: 6px solid transparent;
    left: 10px;
    top: 11px;
    left: -6px;
  }
}

.input-group.date {
  width: 100%;
  > input {
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
  }
}

.shift {
  background-color: lightgreen;
}

li.rightside {
  float: right;
  line-height: 34px;
}

@media (max-width: 767px) {
  .fc-left > h2 {
    font-size: 20px;
  }
  .calendar-checkboxes-container {
    padding-top: 20px;
    display: block;
  }
  .calendar-checkbox {
    margin: 0px !important;
  }
  .fc-toolbar .fc-left {
    padding: 4px;
  }
  .rightside > button {
    display: none;
  }
}

.ranges li {
  background-color: rebeccapurple;
  color: white;
  &.active {
    background-color: purple;
  }
}

.select2-container--bootstrap .select2-results__option[aria-selected="true"] {
  background-color: lightgray;
  color: #262626;
}

.table-sm > {
  tbody > tr > td,
  thead > tr > th {
    height: 20px;
    padding: 3px;
    border-top: 0px;
  }
}

.collapse > tbody > td > td {
  width: 100%;
}

@media print {
  @page {
    size: landscape;
  }
  .print-no {
    display: none;
  }
  .print-hide,
  .nav-tabs {
    visibility: hidden;
  }
}

@media (min-width: 860px) {
  .form-inline > .btn {
    margin-top: 25px;
    margin-left: 10px;
  }
}

.fa-edit,
.fa-map-marker-alt {
  color: rebeccapurple;
  font-size: 1.2em;
}

.fa-edit:hover,
.fa-map-marker-alt:hover {
  color: purple;
}

.dtr-title {
  display: inline-block;
  width: 50%;
}

.child > ul {
  width: 100%;
}

.wtBorder {
  background-color: rebeccapurple !important;
}

.handsontable th {
  background-color: white !important;
}

.colHeader,
.htCore th {
  font-weight: bold !important;
  color: #666666 !important;
}

.wtHolder::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track,
.chart-div::-webkit-scrollbar-track,
.vis-panel::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-track,
.fc-scroller::-webkit-scrollbar-track,
.modal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.wtHolder::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar,
.chart-div::-webkit-scrollbar,
.vis-panel::-webkit-scrollbar,
.scrollbar::-webkit-scrollbar,
.fc-scroller::-webkit-scrollbar,
.modal::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.wtHolder::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb,
.chart-div::-webkit-scrollbar-thumb,
.vis-panel::-webkit-scrollbar-thumb,
.scrollbar::-webkit-scrollbar-thumb,
.fc-scroller::-webkit-scrollbar-thumb,
.modal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rebeccapurple;
}

.nav .open > a {
  background-color: rebeccapurple;
  border-color: rebeccapurple;
  background-color: purple;
  background-image: linear-gradient(purple, purple 50%, purple);
  filter: none;
  border: 1px solid purple;
  &:hover,
  &:focus {
    background-color: rebeccapurple;
    border-color: rebeccapurple;
    background-color: purple;
    background-image: linear-gradient(purple, purple 50%, purple);
    filter: none;
    border: 1px solid purple;
  }
}

.dropdown-menu > .active > a {
  background-color: purple;
  &:hover,
  &:focus {
    background-color: purple;
  }
}

.img-circle {
  border-radius: 50%;
}

.user-menu > {
  img {
    border-radius: 25px;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: -2px 0 0 0;
  }
  span {
    display: inline-block;
    margin-left: 7px;
  }
}

@media (min-width: 768px) {
  .user-menu > {
    img {
      height: 20px;
      width: 20px;
      margin-top: -3px;
    }
    span {
      display: none;
    }
  }
}

@media (min-width: 992px) {
  .user-menu > span {
    display: inline-block;
  }

  .flex {
    display: flex;
  }
}

.nav .open > a {
  border: 0px solid purple;
  &:hover,
  &:focus {
    border: 0px solid purple;
  }
}

.preview-image {
  height: 250px;
  width: 250px;
}

.preview-pdf {
  width: 820px;
  height: 1200px;
  margin-top: 10px;
  > p {
    margin-top: 10px;
  }
}

.login.container {
  align-items: center;
  background: #2c1234;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 100vh;
  padding: 15px;
  width: 100%;

  .login-form {
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 25px 35px;
    width: 400px;
    text-align: center;

    img,
    .form-control-wrap {
      margin-bottom: 20px;
    }

    img {
      height: 35px;
      width: auto;
    }

    .form-control-wrap {
      text-align: left;
      border-bottom: 2px solid #d9d9d9;
      padding-bottom: 5px;

      .control-label {
        color: #666;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.5;
        padding-left: 5px;
      }

      input {
        color: #333;
        font-size: 18px;
        border: 0;
        box-shadow: none;
      }
    }

    h4 {
      color: red;
    }

    .btn {
      border: 0;
      border-radius: 50px;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      padding: 9px 18px;
      width: 100%;
      background-color: #564260;

      &:hover {
        background-color: rgba(86, 66, 96, 0.561);
      }
    }
  }
}

@media (max-width: 991px) {
  .login.container {
    align-items: start;
  }
}

@media (min-width: 768px) {
  .timer .mobile,
  .notification-button .mobile {
    display: none;
  }
}

.timer-container {
  text-align: center;
  width: 100%;
  padding-top: 100%;
  position: relative;

  .timer {
    position: absolute;
    top: 12.5%;
    left: 12.5%;
    width: 75%;
    height: 75%;
    margin: 0px;
    border-radius: 25%;
    font-size: 40px;

    .mobile {
      display: none;
    }
  }
}

.notification-list li {
  padding-left: 10px;
  padding-right: 10px;
}

.notification-icon {
  font-size: 16px;
}

.notification-count {
  font-size: 14px;
  background: rebeccapurple;
  float: right;
}

.notification.dropdown-menu {
  overflow: auto;
  height: 250px;
  width: 250px;
}

@media (min-width: 768px) {
  .notification-count {
    font-size: 8px;
    padding: 0 2px;
    line-height: 10px;
    position: absolute;
    display: block;
    right: 10px;
    background: rebeccapurple;
    z-index: 500;
  }
}

.change-password {
  input {
    margin-bottom: 10px;
  }
  button {
    width: 100%;
    margin: 0px;
  }
}

.profile-info {
  padding: 30px;
  text-align: -moz-center;
  text-align: -webkit-center;
  > {
    img {
      height: 128px;
      width: 128px;
      box-shadow: 0 0px 10px rebeccapurple;
    }
    label {
      background-color: rebeccapurple;
    }
    button {
      width: 100%;
      margin-left: 0px;
      margin-top: 10px;
    }
  }
}

.profile {
  box-shadow: 0 1px 3px hsla(0, 0%, 50%, 0.2);
}

.profile-gray {
  color: #999999;
  margin-right: 10px;
  display: inline-block;
}

.button-group {
  margin-top: 20px;
  margin-bottom: 20px;
}

.icon-cell {
  color: rebeccapurple;
  font-size: 1.2em;
  margin-right: 3px;
}

.view-cell {
  margin-left: 8px;
}

.item-panel {
  padding: 0px;
}

.markdown-preview {
  padding: 8px 12px;
}

.map-input {
  margin: 10px;
  display: inline-block;
  padding: 4px;
  font-size: 13px;
  line-height: 18px;
  color: #555;
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.signature {
  border: 1px solid #ccc;
  width: auto !important;
  height: auto !important;
  padding: 0px !important;
  margin-bottom: 5px;
}

.file-input {
  width: 100%;
  height: 100%;
}

.group-element {
  margin-bottom: 0px;
  margin-top: 20px;
}

.help-icon {
  height: 13px;
  margin-bottom: 2px;
  padding-left: 5px;
}

.panel.statistics > .chart-div {
  height: 500px;
  overflow: auto;
}

@media (min-width: 992px) {
  .panel.statistics {
    height: 332px;
    max-height: 332px;
    > .chart-div {
      height: 73%;
      overflow: auto;
    }
  }
}

@media (min-width: 1200px) {
  .panel.statistics {
    height: 382px;
    max-height: 382px;
    > .chart-div {
      height: 76%;
      overflow: auto;
    }
  }
}

.statistics-row {
  padding-top: 10px;
  margin-left: 10px;
  margin-right: 10px;

  div[class^="col-md-"] {
    padding: 0;
  }
}

.zero-padding {
  padding: 0px;
}

.img-center {
  text-align: -webkit-center;
  text-align: -moz-center;
}

.statistics-title {
  padding-left: 5px;
}

.btn-statistics {
  float: right;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
}

@media (max-width: 767px) {
  .schedule .btn-group {
    margin-top: 0px !important;
    margin-bottom: 24px !important;
  }
  .table-responsive {
    width: auto !important;
  }
  .statistics > .table-responsive,
  .chart-div .table-responsive {
    width: 100% !important;
  }
  .panel.statistics > .chart-div {
    height: 40%;
    overflow: auto;
  }
}

.schedule-checkbox {
  margin-right: 20px;
}

.schedule .form-group {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.schedule-main {
  margin-left: 0px;
  margin-right: 0px;
}

.schedule-table {
  margin-bottom: 0px;
}

.left-body-row {
  height: 27px;
}

.header-row {
}

.right-header-row {
  height: 27px;
}

.schedule-col {
  padding-right: 0px;
  padding-left: 0px;
  border: 1px solid #dddddd;
}

.schedule-col-alt {
  overflow: auto;
  height: 110px;
}

.schedule-border {
  border-right: 2px solid purple;
}

.schedule-checkbox-label {
  margin-top: 5px;
  width: 250px;
}

.schedule-max-width {
  width: 100%;
}

.schedule-form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.schedule-shortcut-cell {
  width: 34px;
}

.schedule-label {
  margin-top: 5px;
}

select {
  cursor: pointer;
}

.clickable-notification:hover {
  background-color: gainsboro;
  cursor: pointer;
}

.editable-cell:hover {
  background-color: gainsboro;
  cursor: pointer;
}

.editable-cell-icon:hover {
  &:after {
    color: rebeccapurple;
    font-family: "Font Awesome 6 Free";
    content: "\f304";
    margin-right: 0.5em;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-weight: 900;
    float: right;
  }
}

.cell-in-edit-form {
  min-width: 180px;
}

.demo-data-info {
  padding: 5px;
  text-align: center;

  > {
    h5 {
      display: inline;
      padding-right: 5px;
    }
  }
}

.vis-label,
.vis-group {
  height: 30px !important;
}

.vis-item.pending {
  border: 2px dashed #0068d0 !important;
}

.vis-item {
  top: 0px !important;
  color: black !important;
  background-color: #d9edf7 !important;
  height: 26px !important;
  border: 1px solid #d9edf7 !important;
  margin: 2px !important;
}

.vis-current-time {
  background-color: mediumpurple;
}

.info-box {
  border: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-color: #dddddd;
}

.sweet-alert {
  border: 1px black solid;
}

.progress-bar {
  background-color: rebeccapurple;
}

.editable-table {
  margin-top: 5px;

  .resource-table {
    margin: 0px;
    padding: 0px;
    width: 100%;
    border: 1px solid #dddddd;

    .fixed-column {
      position: absolute;
      display: inline-block;
      width: auto;
      border-right: 1px solid #ddd;
      background-color: #fff;
    }
  }

  .table {
    margin-bottom: 0px;

    overflow-x: auto;
    overflow-y: auto;

    tbody td.fixed-col {
      position: sticky;
      left: 0;
      background-color: white !important;
    }

    thead th {
      position: sticky;
      top: 0;
      background-color: white !important;
    }

    th {
      font-weight: bold;
      text-align: center;
      min-width: 50px;
    }

    .row-title-cell {
      font-weight: bold;
      border-right-width: 2px;
    }

    .darker-row {
      background-color: #f9f9f9;
    }
  }
}

.minimode {
  display: block;
  margin: 6px;
}

@media (min-width: 768px) {
  .minimode.mobile {
    display: none;
  }
}

button.sp-choose {
  background-color: rebeccapurple !important;
}

.force-visible {
  visibility: visible !important;
}

.displayed-columns > div {
  background: #ddf0dd;
}

.hidden-columns > div {
  background: #f0dddd;
}

.htCore .tooltip {
  position: fixed;
}

button.swal2-confirm {
  background-color: #663399 !important;
}

button.swal2-cancel {
  background-color: #404040 !important;
}

img.swal2-image {
  width: 128px;
  height: 128px;
}

// Needed to override bootstrap default settings
.swal2-popup {
  font-size: 1.6rem !important;
}

.timesheetCommentCell {
  position: relative
}

.timesheetCommentCell:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-left: 6px solid transparent;
  border-top: 6px solid #000000
}

.table-hover > tbody > tr:hover {
  background-color: #f8f4ff;
}

.w-input:invalid {
  border-color: #b94a48;
}

.w-input:invalid:focus {
  box-shadow: 0 0 8px #b94a4898;
}
