.w-select .ts-control {
  border: 1px solid #ccc;
  cursor: pointer !important;
}

.w-select.single .ts-control {
  height: 38px;
}

.w-select-compact.multi .ts-control {
  height: 38px;
}

.w-select-normal.multi .ts-control {
  min-height: 38px;
  padding-right: 22px !important;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
}

.w-select-normal.multi.w-select-height-limit .ts-control {
  max-height: 160px;
}

.w-select-compact.multi .ts-control {
  flex-wrap: nowrap;
  width: 240px;
  padding-left: 26px !important;
}

.w-select-compact.single .ts-control {
  width: 200px;
}

.w-select .ts-control > .item {
  white-space: nowrap;
}

.w-select-compact.multi .ts-control > .item {
  background: #ffffff;
  margin-top: 3px;
  margin-left: 4px;
  padding: 0 !important;
}

.w-select.single .ts-control > .item {
  margin-top: 1px;
  margin-left: 2px;
}

.w-select-right-side {
  position: absolute;
  background: #fff !important;
  border: 0 !important;
  border-radius: 0 !important;
  width: 16px;
  padding: 0 !important;
  text-align: center;
  top: 12px;
  right: 5px;
  z-index: 1;
  height: calc(100% - 22px);
  margin: 0 7px 0 0 !important;
  cursor: pointer;
  display: none;
}

.has-items .w-select-right-side {
  display: block;
}

.w-select-right-side-clear {
  font-size: 18px;
  margin-top: -9px;
  margin-right: -2px;
  color: gray;
}

.w-select-normal .w-select-nr {
  position: absolute;
  bottom: 4px;
  right: 1px;
  z-index: -1;
}

.w-select-normal.dropdown-active .w-select-nr {
  bottom: 24px;
}

.w-select-compact .w-select-nr {
  position: absolute;
  background: #fff !important;
  border: 0 !important;
  border-radius: 0 !important;
  width: 20px;
  padding: 0 !important;
  text-align: center;
  left: 4px;
  margin-top: 3px !important;
  border-right: 1px solid #888888 !important;
}

.w-select .ts-control > .clear-button {
  background: inherit !important;
  box-shadow: 0 0 4px 8px #fff;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 18px;
  color: #808080 !important;
  right: 4px !important;
  top: 7px;
  transform: none;
}

.w-select-normal.single .ts-control > .clear-button {
  right: 14px !important;
}

.w-select.has-items .clear-button {
  opacity: 1;
}

.w-select .ts-dropdown {
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.w-select .ts-dropdown-content > .option.active {
  background: purple;
  color: white;
}

.w-select .ts-dropdown-content > .option.selected {
  background: lightgray;
  color: inherit;
}

.w-select .ts-dropdown-content > .option.selected.active {
  background: rebeccapurple;
  color: white;
}

.w-select .dropdown-input-wrap {
  border-bottom: 1px solid #cccccc;
}

.w-select .ts-dropdown .dropdown-input {
  border: 0;
}

.w-select.multi .ts-dropdown .dropdown-input {
  width: calc(100% - 42px);
}

.w-select-all {
  position: absolute;
  text-align: center;
  width: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.w-select-compact .w-select-all {
  right: 0;
  top: 0;
  border-left: 1px solid #cccccc;
}

.w-select-normal .w-select-all {
  right: 11px;
  top: -37px;
  background-color: rebeccapurple;
  color: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
